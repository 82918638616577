import React, { useState } from 'react';
import ImageBox from './ImageBox';

const App = () => {
  const [qrVisible, setQrVisible] = useState(false);
  const [post_url, setPostURL] = useState('');
  
  const handleExport = () => {
    if (post_url) {
      setQrVisible(true);
    } else {
      alert('Please input a valid link.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 font-roboto p-4 sm:p-6 lg:p-8">
      {/* Header */}
      <div className="mb-8 text-center pt-4">
        <img
          src="/image/logo.png"
          alt="Source Market Logo"
          className="mx-auto mb-4"
          style={{ width: '96px' }}
        />
        <h1 className="text-2xl sm:text-3xl font-bold">QR Image Maker</h1>
      </div>
      {/* Input Section */}
      <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
        <label htmlFor="linkInput" className="block mb-2 text-base sm:text-lg font-medium">
          Input link
        </label>
        <input
          type="text"
          id="linkInput"
          value={post_url}
          onChange={(e) => setPostURL(e.target.value)}
          placeholder="Input your link"
          className="w-full p-2 sm:p-3 mb-4 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleExport}
          className="w-full px-4 py-2 sm:py-3 mb-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Export
        </button>
        <h2 className="mb-4 text-base sm:text-lg font-medium">Get Image</h2>
        {qrVisible && (
          <ImageBox className="w-full" url={post_url} />
        )}
      </div>
    </div>
  );
};

export default App;
