import React, { useRef, useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import QRCode from "react-qr-code";

const ImageBox = ({ url }) => {
  const squareRef = useRef(null);
  const [title, setTitle] = useState("");
  const [img_url, set_img_url] = useState("");

  useEffect(() => {
    const encodedParam = encodeURIComponent(url);
    fetch('https://api.minseok.me/og-image?url=' + encodedParam)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((json) => {
        setTitle(json.og_title);
        set_img_url("https://api.minseok.me" + json.og_image);
      });
  }, [url]);

  const handleDownload = () => {
    const squareElement = squareRef.current;
    html2canvas(squareElement, { useCORS: true, 
      allowTaint: true, 
      imageTimeout: 15000, 
      scale:3,}).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png', 1);
        link.download = 'qr-image.png';
        link.click();
    });
  };

  return (
    <div className="flex flex-col items-center">
      <textarea
        type="text"
        placeholder="문구"
        value={title}
        onChange={x => setTitle(x.target.value)}
        rows="1"
        className="w-full p-2 sm:p-3 mb-4 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <div
        onClick={handleDownload}
        ref={squareRef}
        className="relative w-full max-w-lg h-auto"
        style={{ aspectRatio: '1 / 1' }}
      >
        <img
          src={img_url + '?time=' + new Date().valueOf()}
          alt="Background"
          className="absolute top-0 left-0 w-full h-full object-cover"
          crossOrigin="anonymous"
        />
        <div
          className="relative grid grid-cols-9 bg-gradient-to-b from-transparent to-black justify-end"
          style={{ zIndex: 2, aspectRatio: '1 / 1' }}
        >
        <div className="col-span-6 text-white flex flex-col justify-end p-4">
          <p className="text-[calc(0.6vw+0.6vh+0.6rem)]">{title}</p>
        </div>

        <div className="col-span-3 flex flex-col items-center justify-end p-4">
          <QRCode
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={url}
          />
          <p className="text-[calc(0.4vw+0.4vh+0.4rem)] mt-2 text-white text-center">캡쳐 후 QR을</p>
          <p className="text-[calc(0.4vw+0.4vh+0.4rem)] text-white text-center">클릭해보세요!</p>
        </div>
        </div>
      </div>
      <button
        onClick={handleDownload}
        className="w-full max-w-lg px-4 py-3 mt-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        Download
      </button>
    </div>
  );
};

ImageBox.defaultProps = {
  url: 'https://link.coupang.com/a/bn8aZE'
};

export default ImageBox;
